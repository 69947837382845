import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/chateaudemorey/src/components/Layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Le mariage qui vous ressemble à 100%`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7UxSpAk//8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAAhEh/9oACAEBAAEFAhsFuLF//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABkQAAIDAQAAAAAAAAAAAAAAAAABESAxUf/aAAgBAQAGPwJyPtNZ/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERITFBcWH/2gAIAQEAAT8hfw6GdFDlkVkYh6Vw/9oADAMBAAIAAwAAABAE7//EABcRAQADAAAAAAAAAAAAAAAAAAAhQVH/2gAIAQMBAT8QpOP/xAAXEQADAQAAAAAAAAAAAAAAAAAAESFR/9oACAECAQE/EFR4P//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExUWFxkf/aAAgBAQABPxAS42+MKIilLrWsUVNyPvFoKnTkW014y/AdKGf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mariage nancy",
            "title": "mariage nancy",
            "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg",
            "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/0479a/chateau_de_morey_mariage.jpg 250w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/41099/chateau_de_morey_mariage.jpg 500w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Mariage Nancy`}</strong></p>
    <h2>{`Un lieu magique, pour un mariage inoubliable ! `}<strong parentName="h2">{`sur Nancy`}</strong></h2>
    <p>{`Situé dans entre Nancy et Metz, en pleine campagne, le château de Morey est un lieu exceptionnel pour organiser le plus beau jour de votre vie.
Un château XVIe pour décor avec un jardin aux arbres centenaires.
Dans un site merveilleusement préservé, le château de Morey se découvre au bout d’une majestueuse allée cavalière.`}</p>
    <h2>{`Idéal si vous rechercher une location de salle pour un mariage`}</h2>
    <p>{`L’accueil des mariés et de leurs invités s’effectue par la grille d’honneur.
La salle de réception en pierre apparente, peut accueillir 65 convives au repas.
Une autre salle, dotée d’une jolie fresque, sera votre salle de danse.
Le soin apporté à la restauration des lieux en fait un cadre particulièrement raffiné.`}</p>
    <p>{`Équipements également à votre disposition:`}</p>
    <ul>
      <li parentName="ul">{`piste de danse`}</li>
      <li parentName="ul">{`cuisines pour traiteurs`}</li>
      <li parentName="ul">{`sanitaires`}</li>
      <li parentName="ul">{`Un petit salon, contigu à la salle d’honneur, représente l’endroit idéal pour les enfants.`}</li>
      <li parentName="ul">{`Tables et chaises à disposition`}</li>
    </ul>
    <h2>{`Votre vin d’honneur`}</h2>
    <p>{`Plusieurs possibilités vous sont proposées pour votre vin d’honneur : en plein air sous abri dans le parc du château par beau temps, ou dans les caves voutées du château en cas de pluie.
Dans le parc vous pourrez flâner, prendre de magnifiques photos et offrir à vos convives un véritable « voyage » au milieu des nombreux arbres centenaires. Accueil personnalisé. Chaque réception est unique. C’est pour cette raison qu’au château de Morey vous êtes accueillis personnellement.`}</p>
    <h2>{`Les salles du château`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26a0712157345476aee40612230bac3d/c08c5/mariage_chateau_salle.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125.2%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAwH/2gAMAwEAAhADEAAAAV0o0HslJp1fO6NZg43P/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMSIjP/2gAIAQEAAQUCvnGbDtTMhmjTIGLK+sFWahies//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/AWP/xAAWEQEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQIBAT8BI3//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRECEicYH/2gAIAQEABj8CTZ6Mp9nF0aVii8xIY//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQVGRocH/2gAIAQEAAT8hwA7k2IA2+4xLAqlcQyAXnqaAFckyGysZ3l5uMvc/CfCx5Z//2gAMAwEAAgADAAAAEEfeg//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIRH/2gAIAQMBAT8QQ7Dk3b//xAAYEQACAwAAAAAAAAAAAAAAAAAAARAhYf/aAAgBAgEBPxB2Hif/xAAgEAEAAwABAwUAAAAAAAAAAAABABEhQVGx8DGBkaHh/9oACAEBAAE/EN3KaT1Q/YdZAV7y4AtvKQVFBVfA3pCAgy2r47zOuBTYvOwhnijPGE0vCFGhEIgebGdruZ4noT7zP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "location de salles nancy",
            "title": "location de salles nancy",
            "src": "/static/26a0712157345476aee40612230bac3d/c08c5/mariage_chateau_salle.jpg",
            "srcSet": ["/static/26a0712157345476aee40612230bac3d/0479a/mariage_chateau_salle.jpg 250w", "/static/26a0712157345476aee40612230bac3d/41099/mariage_chateau_salle.jpg 500w", "/static/26a0712157345476aee40612230bac3d/c08c5/mariage_chateau_salle.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Location de salles nancy`}</strong></p>
    <p>{`Tarifs :
Pour les tarifs, veuillez prendre contact par mail, téléphone ou directement sur notre site internet.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Recevez votre devis</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      